.btn-next {
    font-size: xx-large;
    font-weight: bold;
}

.btn-reinit {
    margin-left: 10px;
    width: 200px;
    height: 75px;
    line-height: 300px;
    vertical-align: baseline;
    font-size: x-large!important;
    font-weight: bold!important;
}

.btn-result {
    width: 300px;
    height: 100px;
    font-size: xx-large!important;
    font-weight: bold!important;
    border: none!important;
}
