.card {
}

.card-title {
    line-height: 160px;
    vertical-align: middle;
}

.card button {
    font-size: xx-large;
}

