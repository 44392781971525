.tosolve {
    font-size: xx-large;
}

.nodisplay {
    display: none;
}

.show {
    display: block;
}

.valid {
    opacity: 0.33;
    transition: opacity 1s, visibility 0.3s;
}

.invalid {
    background-color: burlywood!important;
}

.button-invalid {
    cursor: not-allowed;
}

.toolbar {
    margin-top: 40px!important;
}
